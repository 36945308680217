import axios from 'axios'
axios.defaults.withCredentials = true;

//Get website information
export const getWebsiteInfos = (callback)=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}/website`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Submit a partnership request
export const submitRequest = (data, callback)=>{
    axios.post(`${process.env.REACT_APP_BASE_URL}/website/request`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}
import React, { useEffect, useState, useRef } from 'react';
import AdminSidebar from '../../components/admin/sidebar/admin-sidebar.js.js';
import st from "./adminInterface.module.css"
import UsersList from '../../components/admin/users/usersList/userList.js';
import ArchivedUsersList from '../../components/admin/users/archivedUsers.js/archivedUsersList.js';
import PartnershipRequests from '../../components/admin/providers/partnershipRequests/partnershipRequests.js';
import Home from '../home/home.js';
import AboutUs from '../aboutus/aboutus.js';

function AdminInterface(props) {
    const { user, website } = props;
    const [collapsed, setCollapsed] = useState(false);
    const [toggled, setToggled] = useState(false);
    const [component, setComponent] = useState(() => sessionStorage.getItem("component") || "dashboard");
    const main = useRef();
    const previousComponent = useRef("dashboard");

    useEffect(() => {
        collapsed ? main.current.style.marginLeft = "80px" : main.current.style.marginLeft = "250px";
    }, [collapsed]);

    useEffect(() => {
        sessionStorage.setItem("component", component);
        
        const previousElement = document.getElementById(previousComponent.current);
        previousElement && (previousElement.style.display = "none");

        const element = document.getElementById(component);
        element && (element.style.display = "block") ;

        previousComponent.current = component;
    }, [component]);

    useEffect(() => {
        const storedComponent = sessionStorage.getItem("component");
        if (storedComponent) {
            setComponent(storedComponent);
        }
    }, []);

    return (
        <div>
            <div className={`${st.adminBar}`}>
                <span onClick={() => { setCollapsed(!collapsed) }} className={`${st.sidebarToggler} bi bi-list`} style={{ marginRight: "10px" }}></span>
                <span onClick={() => { setToggled(!toggled); setCollapsed(false) }} className={`${st.sidebarToggler1} bi bi-list`} style={{ marginRight: "10px" }}></span>
                MenuOnline
            </div>

            <div className={`${st.bigMain}`}>
                <div style={{ position: "absolute", width: '100%', height: '100%', overflowY: 'auto' }}>
                    <div id='main' ref={main} className={`${st.main}`}>
                        <div id='dashboard'>Hello dashboard</div>

                        <div id='home' style={{ display: "none" }}><Home user={user} website={website} /></div>
                        <div id='about' style={{ display: "none" }}><AboutUs/></div>
                        <div id='blog' style={{ display: "none" }}><PartnershipRequests/></div>
                        <div id='contact' style={{ display: "none" }}><PartnershipRequests/></div>

                        <div id='requestsList' style={{ display: "none" }}><PartnershipRequests/></div>
                        <div id='providersList' style={{ display: "none" }}></div>
                        <div id='usersList' style={{ display: "none" }}><UsersList /></div>
                        <div id='archivedUsersList' style={{ display: "none" }}><ArchivedUsersList /></div>
                    </div>
                </div>
                <AdminSidebar
                    collapsed={collapsed}
                    toggled={toggled}
                    setCollapsed={setCollapsed}
                    setToggled={setToggled}
                    setComponent={setComponent} />
            </div>
        </div>
    );
}

export default AdminInterface;

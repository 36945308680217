import axios from "axios";

//Send an email to reset the password
export const forgotPassword = (email,callback)=>{
    axios.post(`${process.env.REACT_APP_BASE_URL}/mail/forgot-password`,email)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Reset the password
export const resetPassword = (newPassword,token,callback)=>{
    const headers= {
        authorization:`Bearer ${token}`
    }
    axios.post(`${process.env.REACT_APP_BASE_URL}/mail/reset-password`,newPassword,{headers})
    .then((res) => callback(res))
    .catch((err) => callback(err));
}

//Send an email Verification 
export const verifyEmail = (email,callback)=>{
    axios.post(`${process.env.REACT_APP_BASE_URL}/mail/verify-email`,email)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}
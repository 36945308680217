import "./App.css";

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { logout } from "./services/authApi";
import { googleLogout } from "@react-oauth/google";

import Home from "./pages/home/home";
import Login from "./pages/login/login";
import Signup from "./pages/signup/signup";
import ResetPassword from "./pages/resetPassowrd/resetPassword";
import AboutUs from "./pages/aboutus/aboutus";

import AppNavbar from "./components/navigation/app-navigation/app-navbar";
import AdminInterface from "./pages/admin-interface/adminInterface";
import { getUser } from "./services/userApi";
import { getWebsiteInfos } from "./services/website";
import Profile from "./pages/profile/profile";
import Security from "./pages/security/security";
import Dashboard from "./pages/dashboard/dashboard";
import MyPlaces from "./pages/myPlaces/myPlaces";
import Loading from "./pages/loading/loading";

function App() {
  const [websiteInfos, setWebsiteInfos] = useState();
  const [user, setUser] = useState(localStorage.getItem("username") || sessionStorage.getItem("username"));
  const [expiresAt, setExpiresAt] = useState(localStorage.getItem("expiresAt") || sessionStorage.getItem("expiresAt"));
  const role = localStorage.getItem("role") || sessionStorage.getItem("role");
  const [userLogged, setUserLogged] = useState({});

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getWebsiteInfos((cb) => {
      if (cb.status === 200) {
        setWebsiteInfos(cb.data.infos);
      }
    })
  }, [])


  useEffect(() => {
    if (user) {
      getUser((cb) => {
        if (cb.status === 200) {
          setUserLogged(cb.data.user)
          if (cb.data.user.isSuspended || (new Date(cb.data.user.timeout) > new Date())) {
            logout((cb) => {
              if (cb.status === 200) {
                localStorage.clear();
                sessionStorage.clear();
                googleLogout();
                setUser(null);
                setExpiresAt(null);
                window.location.href = "/login";
              } else {
                console.error(cb);
              }
            });
          }
        }
      })
    }
  },[user])

  useEffect(() => {
    const today = new Date().getTime();
    const timeDifference = expiresAt - today;
    if (timeDifference <= 0) {
      if (expiresAt) {
        logout((cb) => {
          if (cb.status === 200) {
            localStorage.clear();
            sessionStorage.clear();
            googleLogout();
            setUser(null);
            setExpiresAt(null);
            window.location.href = "/login";
          } else {
            console.error(cb);
          }
        });
      }
    }

  }, [expiresAt]);

  useEffect(() => {
    if (websiteInfos && userLogged) {
      setTimeout(() => {
        setLoaded(true)
      }, 1000);
    }
  },[setLoaded, websiteInfos, userLogged])

  return (
    <>
    {loaded ? (<Router>
      {user && userLogged && <AppNavbar user={userLogged} website={websiteInfos} />}
      <Routes>
        <Route path="/" element={<Home user={userLogged} website={websiteInfos} />} />
        <Route path="/about-us" element={<AboutUs website={websiteInfos} />} />
        <Route path="/admin" element={userLogged.role === "admin" ? <AdminInterface user={userLogged} website={websiteInfos} /> : <Navigate to="/login" />} />

        {user ?
          <Route path="/account">
            <Route path="profile" element={<Profile website={websiteInfos} />} />
            <Route path="security" element={<Security website={websiteInfos} />} />
            {role === 'provider' ?
              <>
                <Route path="dashboard" element={<Dashboard role={role} website={websiteInfos} />} />
                <Route path="myPlaces" element={<MyPlaces />} website={websiteInfos} />
              </>
              : <>
                <Route path="dashboard" element={<Navigate to="/account/profile" />} />
                <Route path="myPlaces" element={<Navigate to="/account/profile" />} />
              </>
            }
          </Route>
          :
          <Route path="*" element={<Navigate to="/login" />} />}

        {user ? (
          <>
            <Route path="/login" element={<Navigate to="/" />} />
            <Route path="/signup" element={<Navigate to="/" />} />
            <Route path="/reset-password" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route path="/login" element={<Login website={websiteInfos} />} />
            <Route path="/signup" element={<Signup website={websiteInfos} />} />
            <Route path="/reset-password" element={<ResetPassword website={websiteInfos} />} />
          </>
        )}
      </Routes>
    </Router>) : (<Loading/>) }
    </>
    
  );
}

export default App;

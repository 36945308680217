import st from './home.module.css'
import Footer from '../../components/footer/footer';
import AppNavbar from '../../components/navigation/app-navigation/app-navbar';

function Home(props) {
    const { user, website } = props;
    const username = localStorage.getItem("username") || sessionStorage.getItem("username");
    return (
        <>
            {!username && user.role !== "admin" && <AppNavbar website={website} />}
            <div className={`${st.page}`}>
                <div className={`${st.body} text-center`}> Welcome home </div>
                <Footer website={website} />
            </div>
        </>
    );
}

export default Home;

import st from "./login.module.css";
import { Link } from "react-router-dom";
import React, { useState } from 'react';
import { login, loginWithGoogle } from "../../services/authApi";
import ForgotPassModal from "../../components/modals/forgotPassModal/forgotPassModal";
import { useGoogleLogin } from "@react-oauth/google";


function Login() {
  const initialValues = {
    email: "",
    password: ""
  }

  const [err, setErr] = useState(false);
  const [gglErr, setgglErr] = useState(false);
  const [timeOut, setTimeOut] = useState(false);
  const [formValues, setFormValues] = useState(initialValues);
  const [remember, setRemember] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const [resEmail, setResEmail] = useState('');
  const [resErr, setResErr] = useState(false)
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setErr(false);
    setFormValues({ ...formValues, [name]: value });
  }

  const resetValues = () => {
    setResEmail('');
    setLoading(false);
    setSent(false);
    setResErr(false);
  }

  const submit = (e) => {
    e.preventDefault();
    let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let passwordPattern = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
    if (!formValues.email || !formValues.password) {
      setErr("required");
      return;
    }
    if (!emailPattern.test(formValues.email)) {
      setErr("email");
      return;
    }
    if (!passwordPattern.test(formValues.password)) {
      setErr("password");
      return;
    }
    login(formValues, (cb) => {
      if (cb.status === 200) {
        var storage = remember ? localStorage : sessionStorage;
        storage.setItem("username", cb.data.username);
        storage.setItem("expiresAt", cb.data.expiresAt);
        storage.setItem("googleAccount", cb.data.googleAccount);
        storage.setItem("role", cb.data.role);
        cb.data.role === "admin" ? 
        window.location.href = "/admin" : 
        window.location.href = "/";
        return;
      } else if (cb.response.status === 401 || cb.response.status === 400) {
        setErr("credentials")
        return;
      }
      else if (cb.response.status === 403 && cb.response.data && cb.response.data.message === "Suspended") {
        setErr("suspended")
        return;
      }
      else if (cb.response.status === 403 && cb.response.data && cb.response.data.message === "Timeout") {
        let remainingSeconds = (new Date(cb.response.data.timeout)) - (new Date());
        let remainingDays = Math.ceil(remainingSeconds / (1000 * 60 * 60 * 24));
        setTimeOut(remainingDays);
        setErr("timeout")
        return;
      }
      else {
        setErr("server")
      }
    })
  }

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    onSuccess: async (res) => {
      loginWithGoogle(res.code, (cb) => {
        const storage = remember ? localStorage : sessionStorage;
        if (cb.status === 200) {
          storage.setItem("username", cb.data.username);
          storage.setItem("expiresAt", cb.data.expiresAt);
          storage.setItem("googleAccount", cb.data.googleAccount);
          storage.setItem("role", cb.data.role);
          window.location.href = "/";
        } else if (cb.status === 201) {
          sessionStorage.setItem("username", cb.data.username);
          sessionStorage.setItem("expiresAt", cb.data.expiresAt);
          sessionStorage.setItem("googleAccount", cb.data.googleAccount);
          sessionStorage.setItem("role", cb.data.role);
          window.location.href = "/";
        } else if (cb.response.status === 403 && cb.response.data && cb.response.data.message === "Timeout") {
          let remainingSeconds = (new Date(cb.response.data.timeout)) - (new Date());
          let remainingDays = Math.ceil(remainingSeconds / (1000 * 60 * 60 * 24));
          setTimeOut(remainingDays);
          setgglErr("timeout")
          return;
        } else if (cb.response.status === 401) {
          setgglErr("suspended")
          return;
        } else {
          console.error(cb);
        }
      })
    },
    onError: errorResponse => console.log(errorResponse),
  });


  return (
    <div className={`${st.page}`}>
      <div className="container d-flex justify-content-center">
        <div className={`card ${st.loginCard} p-5`}>
          <form onSubmit={submit}>
            <div className="row mb-4">
              <div className="col-4 px-0">
                <div ><img className={st.logo} src="./images/logo-icon.png" alt="menuonline" /></div>
              </div>
              <div className="col-8">
                <p className={`${st.title}`}>S'identifier</p>
              </div>
            </div>
            <div className="row">
              <div className="form-group">
                <label className="form-label">Adresse E-mail<span className="text-danger">*</span></label>
                <input type="text" value={formValues.email} onChange={handleChange} name="email"
                  className={`form-control ${err ? "is-invalid" : ""}`} id="validationCustom01" />
              </div>

              <div className="form-group mt-3 position-relative">
                <label className="form-label">Mot de passe<span className="text-danger">*</span></label>
                <div className={`${st.passwordToggle}`}>
                  <input type={showPassword ? "text" : "password"} value={formValues.password} onChange={handleChange} name="password" className={`form-control ${err ? "is-invalid" : ""}`} id="validationCustom02" />
                  <div className={`${st.passwordToggleIcon}`}>
                    <span className={showPassword ? "bi bi-eye-slash" : "bi bi-eye"} onClick={() => setShowPassword(!showPassword)} ></span>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-2">
              <span className="text-danger  mb-2">
                {err === 'required' && "Tous les champs sont requis !"}
                {err === 'credentials' && "Le mot de passe ou l'adresse email est incorrect !"}
                {err === 'email' && "Entrer une email valide !"}
                {err === 'password' && "Format de mot de passe est incorrecte !"}
                {err === 'server' && "Une erreur survenue, veuillez réessayer plus tard !"}
                {err === 'suspended' && "Votre compte est suspendu !"}
                {err === 'timeout' && timeOut ? "Vous êtes suspendu pendant " + timeOut + " jour(s)" : ""}
              </span>

              <div className="col-lg-6">
                <div className={`form-check form-switch ${st.formCheck}`}>
                  <input className={`form-check-input ${st.formCheckInput}`} type="checkbox" checked={remember} onChange={(e) => setRemember(e.target.checked)} />
                  <label className="form-check-label">Se souvenir de moi</label>
                </div>
              </div>
              <div className="col-lg-6 text-end">
                <div>
                  <span data-bs-toggle="modal" data-bs-target="#forgotPassModal" onClick={resetValues} className={`${st.forgot}`}>Mot de passe oublier ?</span>
                </div>

              </div>
            </div>
            <div className="row mt-4 d-flex justify-content-center">
              <button type="submit" className={`${st.btn} mb-1`}> <i className="bi bi-box-arrow-in-right"></i> Login</button>
            </div>
            <div className="row no gutters mb-1">
              <div className="col-4"><hr /></div>
              <div className="col-4 mt-1 px-0 text-center">Continuer avec</div>
              <div className="col-4"><hr /></div>
            </div>
            <div className="row d-flex justify-content-center mb-2">
              <button type="button" onClick={googleLogin} className={`text-center mt-1 ${st.customBtn}`}>
                <img className={st.icon} src="./images/google.png" alt="icon" />
                <span className={`${st.buttonText}`}>Google</span>
              </button>
            </div>
            <div className="row">
              <span className="text-danger text-center mt-1">
                {gglErr === "timeout" && timeOut ? "Vous êtes suspendu pendant " + timeOut + " jour(s)" : ""}
                {gglErr === "suspended" && "Votre compte est suspendu !"}
              </span>
            </div>
            <div className="row mt-2 text-center">
              <div className="col">Vous n'avez pas un compte? <Link to="/signup" className={`${st.signup}`}>S'inscrire</Link></div>
            </div>
          </form>

        </div>

        <ForgotPassModal email={resEmail} setEmail={setResEmail} err={resErr} setErr={setResErr} sent={sent} setSent={setSent} loading={loading} setLoading={setLoading} />

      </div>
    </div>
  );
}

export default Login;
import axios from 'axios'
axios.defaults.withCredentials = true;

//Get user
export const getUser = (callback)=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}/user`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Change user password
export const changeAccountPassword = (data, callback)=>{
    axios.put(`${process.env.REACT_APP_BASE_URL}/user/change-password`, data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//check user email
export const checkAccountEmail = (data,callback)=>{
    axios.put(`${process.env.REACT_APP_BASE_URL}/user/check-email`, data)
    .then((res) => callback(res))
    .catch((err) => callback(err));
}

//change user email
export const changeAccountEmail = (data,callback)=>{
    axios.put(`${process.env.REACT_APP_BASE_URL}/user/change-email`, data)
    .then((res) => callback(res))
    .catch((err) => callback(err));
}
//Update user
export const updateUser = (data, callback)=>{
    axios.put(`${process.env.REACT_APP_BASE_URL}/user`,data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Sign Up
export const signup = (data, callback)=>{
    axios.delete(`${process.env.REACT_APP_BASE_URL}/user`,data)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}
import React, { useState, useEffect} from 'react';
import st from './providerRequest.module.css'

import { submitRequest } from '../../../services/website';

function ProviderRequest(props) {
    const { user, err, setErr, resetValues } = props;
    const initialValues = {
        lastname: "",
        firstname: "",
        email: "",
        phone: "",
        address: "",
        businessname: ""
    }
    const [formValues, setFormValues] = useState(initialValues);

    useEffect(() => {
        if (user) {
            setFormValues({
                lastname: user.lastname || "",
                firstname: user.firstname || "",
                email: user.email || "",
                phone: user.phone || "",
                address: "",
                businessname: ""
            });
        }
    }, [user, resetValues]);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setErr(false);
        setFormValues({ ...formValues, [name]: value });
    }
    const submit = () => {
        const empty = Object.values(formValues).some(value => value === "");
        if (empty) {
            setErr("req");
            return;
        }
        submitRequest(formValues, (cb)=>{
            if (cb.status === 200) {
                setErr(false)
                setFormValues(initialValues);
                document.getElementById("closePorviderRequest").click();
            }
        })
    }



    return (
        <div className={`modal fade ${st.modal}`} id="providerRequest" tabIndex="1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className="row mb-3 p-3">
                            <div className="col-10"><h3>Devenir un prestataire</h3></div>
                            <div className="col-2 text-end"><button id='closePorviderRequest' type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button></div>
                        </div>
                        <div className="row p-2">
                            <p>Pour devenir prestataire, il faut remplir cette formulaire à fin de gérer votre demande. (<span >Tous les champs sont obligatiores</span>).</p>
                        </div>
                        <form className='p-2'>
                            <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">Nom<span className="text-danger">*</span></label>
                                        <input type="text" placeholder='Entrer votre nom' value={formValues.lastname} onChange={handleChange} name="lastname"
                                            className={`form-control ${(err && !formValues.lastname) && "is-invalid"}`} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">Prénom<span className="text-danger">*</span></label>
                                        <input type="text" placeholder='Entrer votre prénom' value={formValues.firstname} onChange={handleChange} name="firstname"
                                            className={`form-control ${(err && !formValues.firstname) && "is-invalid"}`} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label">Adresse e-mail<span className="text-danger">*</span></label>
                                        <input type="text" placeholder='Entrer votre adresse email' value={formValues.email} onChange={handleChange} name="email"
                                            className={`form-control ${(err && !formValues.email) && "is-invalid"}`} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <div className="form-group">
                                        <label className="form-label">Nom de l'établissement<span className="text-danger">*</span></label>
                                        <input type="text" placeholder='Entrer le nom de votre établissement' value={formValues.businessname} onChange={handleChange} name="businessname"
                                            className={`form-control ${(err && !formValues.businessname) && "is-invalid"}`} />
                                    </div>
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">Numéro de téléphone<span className="text-danger">*</span></label>
                                        <input type="tel" placeholder='Entrer votre téléphone' value={formValues.phone} onChange={handleChange} name="phone"
                                            className={`form-control ${(err && !formValues.phone) && "is-invalid"}`} />
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-group">
                                        <label className="form-label">Adresse<span className="text-danger">*</span></label>
                                        <input type="text" placeholder='Entrer votre adresse' value={formValues.address} onChange={handleChange} name="address"
                                            className={`form-control ${(err && !formValues.address) && "is-invalid"}`} />
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div className="row p-2">
                            <div className="col">
                                <span className="text-danger">
                                    {err === "req" && "Tous les champs sont obligatoires"}
                                </span>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col text-end">
                                <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                <button onClick={submit} className={`${st.save}`}>Enregistrer</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ProviderRequest;
import { useEffect, useState, useRef } from "react";
import st from "./profile.module.css";
import { getUser, updateUser } from "../../services/userApi";
import ClientNavbar from "../../components/navigation/client-navbar/client-navbar";

function Profile() {
  const imgRef = useRef(null);
  const [user, setUser] = useState();
  const [action, setAction] = useState(false);
  const [err, setErr] = useState(false);
  const [avatar, setAvatar] = useState();
  const [load, setLoad] = useState(false);
  const [update, setUpdate] = useState(false);
  const [upload, setUpload] = useState(true);
  const [birthdate, setBirthdate] = useState("");

  useEffect(() => {
    getUser((cb) => {
      if (cb.status === 200) {
        setUser(cb.data.user);
        setBirthdate(cb.data.user.birthdate.slice(0, 10))
      }
    });
  }, [action]);

  const handleChange = (event) => {
    setErr(false)
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  const imgView = document.getElementById("img-view");
  const inputFile = document.getElementById("input-file");

  const uploadImage = (e) => {
    if (inputFile.files.length > 0) {
      let imgLink = URL.createObjectURL(inputFile.files[0]);
      imgView.src = imgLink;
      if (imgRef.current) {
        imgRef.current.src = imgLink;
      }
      setAvatar(inputFile.files[0]);
    } else {
      setUpload(false)
      console.error("No file selected.");
    }
  };

  const UpdateUserAccount = (e) => {
    e.preventDefault();

    if (!user.firstname || !user.lastname || !user.email) {
      setErr("req");
    } else {
      setLoad(true);
      const updatedUser = {
        firstname: user.firstname,
        lastname: user.lastname,
        phone: user.phone || "",
        birthdate: user.birthdate || "",
        email: user.email,
      };
      const formData = new FormData();
      Object.keys(updatedUser).forEach((key) => {
        formData.append(key, updatedUser[key]);
      });
      if (avatar instanceof File) {
        formData.append("avatar", avatar);
      }

      //  for (const pair of formData.entries()) {
      //   console.log(pair[0], pair[1]);
      // }

      updateUser(formData, (cb) => {
        if (cb.status === 200) {
          setTimeout(() => {
            setLoad(false);
            setUpdate(true);
          }, 2000);

          setTimeout(() => {
            setUpdate(false)
          }, 4000);

          setAction(!action);
        } else console.error(cb);
      })
    }
  };

  return (
    <div className="app-page">
    <ClientNavbar />
      <div className={`container ${st.body}`}>
        {user && (
          <>
              <form onSubmit={UpdateUserAccount} encType="multipart/form-data" className="row">
                <div className="col-xl-4">
                  <div className={`card mb-3 ${st.card}`}>
                    <div className={`${st.cardheader} card-header`}>
                      Photo de profil
                    </div>
                    <div className={`card-body text-center`}>
                      <div id="img-view" className="mb-3 mt-1">
                        <img
                          className={`${st.img} ${st.roundedCircle}`}
                          src={user.avatar ? `${process.env.REACT_APP_SERVER_PUBLIC}${user.avatar}` : "./images/userdefault.png"}
                          alt="avatar"
                          ref={imgRef}
                        />
                      </div>
                      <div className="row">
                        {!upload && (<div className="d-flex justify-content-center mb-2"><div className={`text-danger`}>la taille de l'image est trop grande</div></div>)}
                      </div>
                      <div
                        id="drop-area"
                        htmlFor="input-file"
                        className={`${st.dropArea}`}
                        onDragOver={(e) => {
                          e.preventDefault();
                        }}
                        onDrop={(e) => {
                          e.preventDefault();
                          if (e.dataTransfer.files) {
                            inputFile.files = e.dataTransfer.files;
                          }
                          uploadImage();
                        }}
                        onClick={() =>
                          document.getElementById("input-file").click()
                        }
                      >
                        <input
                          type="file"
                          accept="image/*"
                          id="input-file"
                          onChange={uploadImage}
                          style={{ display: "none" }}
                        />

                        <div className={st.icon}>
                          <i className="bi bi-cloud-arrow-up"></i>
                        </div>

                        <div className="mb-1" style={{ color: "#49494891" }}>
                          Télécharger une nouvelle image
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-8">
                  <div className={`card ${st.card} mb-4`}>
                    <div className={`${st.cardheader} card-header`}>
                      Détails du profil
                    </div>
                    <div className={`card-body ${st.cardBody}`}>
                      <div className="row gx-3 mb-3">
                        <div className="col-md-6 ">
                          <label className="small mb-1">Nom<span className="text-danger">*</span></label>
                          <input
                            className={`form-control ${err === "req" && !user.lastname ? "is-invalid" : ""}`}
                            type="text"
                            placeholder="Entrez votre nom"
                            name="lastname"
                            onChange={handleChange}
                            value={user.lastname}
                          />
                        </div>

                        <div className="col-md-6 ">
                          <label className="small mb-1">Prénom<span className="text-danger">*</span></label>
                          <input
                            className={`form-control ${err === "req" && !user.firstname ? "is-invalid" : ""}`}
                            type="text"
                            placeholder="Entez votre prénom"
                            name="firstname"
                            onChange={handleChange}
                            value={user.firstname}
                          />
                        </div>
                      </div>

                      <div className="row gx-3 mb-3">
                        <div className="col-md-6">
                          <label className="small mb-1">
                            Numéro de téléphone
                          </label>
                          <input
                            className={`form-control`}
                            id="inputLastName"
                            type="tel"
                            placeholder="Entez votre numéro de téléphone"
                            name="phone"
                            onChange={handleChange}
                            value={user.phone || ""}
                          />
                        </div>

                        <div className="col-md-6">
                          <label className="small mb-1">Birthdate</label>
                          <input
                            className={`form-control `}
                            type="date"
                            name="birthdate"
                            onChange={handleChange}
                            value={birthdate || ""}
                          />
                        </div>
                      </div>

                      <div className="row">
                        <div className="mb-3">
                          <label className="small mb-1">Adresse E-mail</label>
                          <input
                            className={`form-control text-muted ${err === "req" && !user.email ? "is-invalid" : ""}`}
                            type="email"
                            placeholder="Enter your email address"
                            name="email"
                            onChange={handleChange}
                            value={user.email}
                            disabled
                          />
                        </div>
                      </div>

                      <div className="row">
                        {load && (<div className="d-flex justify-content-center mb-2"><div className={`${st.loader}`}></div></div>)}
                        {update && (<div className="d-flex justify-content-center mb-2"><div className={`text-success`}>Profil enregistré</div></div>)}
                        {err === "req" && (<div className="d-flex justify-content-center mb-2"><div className={`text-danger`}>Veuillez remplir tous les champs obligatoires</div></div>)}
                        <div className="d-flex justify-content-center">
                          <button className={`${st.myBtn}`} type="submit">
                            Sauvegarder
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
          </>
        )}
      </div>
    </div>
  );
}

export default Profile;

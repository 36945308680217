import { useEffect, useState } from "react";
import {
  changeAccountEmail,
  checkAccountEmail,
  getUser,
} from "../../../services/userApi";
import st from "./change-email.module.css";

function ChangeEmail() {
  const [userEmail, setUserEmail] = useState("");
  const [password, setPassword] = useState("");
  const [err, setErr] = useState(false);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    getUser((cb) => {
      if (cb.status === 200) {
        setUserEmail(cb.data.user.email);
      }
    });
  }, []);

  const changeEmail = (event) => {
    event.preventDefault();

    let emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!userEmail) {
      setErr("required");
      return;
    }

    if (!emailPattern.test(userEmail)) {
      setErr("emailPattern");
      return;
    }

    checkAccountEmail({ email: userEmail }, (cb) => {
      console.log(cb);
      setLoading(true);
      if (cb.status === 200) {
        setTimeout(() => { setLoading(false);setValid(true);}, 2000);
        // setTimeout(() => { }, 4000);

        console.log(password,userEmail);
        if (password) {
            changeAccountEmail({email: userEmail, password: password}, (cb) => {
                setLoading(true);
            if (cb.status === 200) {
                setTimeout(() => { setLoading(false);setErr("success");}, 2000);
               setTimeout(() => { setErr(false);setValid(false);}, 4000);
            }else console.log("mochkla");
            });
        }
        
      } else if (cb.response.status === 422) {
        setTimeout(() => { setLoading(false);setErr("inUse");}, 2000);        
        return;
      } else {
        setTimeout(() => { setLoading(false);setErr("server");}, 2000);        
        return;}
    });
  };
  return (
    <div className="card mb-4">
      <div className={`${st.cardheader} card-header`}>
        Changer votre Adresse E-mail
      </div>
      <div className="card-body">
        <form onSubmit={changeEmail}>
          {!valid ? (
            <input
              className="form-control"
              type="text"
              placeholder="Entrez une nouvelle adresse e-mail"
              onChange={(e) => {setErr(false);setUserEmail(e.target.value); }}
              name="email"
              value={userEmail}
            />
          ) : (
            <>
              <label className="small mb-1">
                Votre mot de passe<span className="text-danger">*</span>
              </label>
              <input
                className="form-control"
                type="password"
                placeholder="Entrez votre mot de passe"
                onChange={(e) => {setErr(false);setPassword(e.target.value);}}
                name="password"
                value={password}
              />
            </>
          )}
          <div className="row">
            <div className="col-lg-8 mt-2" style={{ paddingLeft: "20px" }}>
              <span className="text-success">
                   {err === "success" && "votre adresse e-mail a été modifiée avec succès"} 
              </span>
              <span className="text-danger">   
                {err === "required" && "Ce champ est obligatoire !"}
                {err === "inUse" && "Cette adresse email est déjà utilisée !"}
                {err === "emailPattern" && "Le format d'adresse e-email est invalide !"}
              </span>
            </div>
            <div className="col-lg-4 mt-3">
              <div className="text-end">
                {loading && <span className={`${st.loader}`}></span>}
                <button className={`${st.Btn}`} type="submit">
                  {!valid ? "Suivant" : "Enregister"}
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangeEmail;

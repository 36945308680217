import st from "./footer.module.css"
import { Link } from 'react-router-dom'
function Footer(props) {
    const {website} = props;
    
    return (

        <div>
            <hr className={st.hr} />
            <div className="container">
                <div className="row mt-5">
                    <div className="col-lg-5">
                        <div className="row mb-4">
                            <div className="col-2"><img src="./images/logo-icon.png" alt="MenuOnline" className={`${st.logo}`} /></div>
                            <div className="col-9 px-0 mt-2"><b className={`${st.logoText}`}>{website && website.name ? website.name : "Title"}</b></div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <p>Consulter notre répertoire de menu en ligne et choisir la bonne destination pour vous et vos proches.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-2">
                        <div className={`row mb-3`}><b>Entreprise</b></div>
                        <div className={`row mb-2`}><Link className={`${st.links}`}>À propos</Link></div>
                        <div className={`row mb-2`}><Link className={`${st.links}`}>Carrières</Link></div>
                        <div className={`row mb-2`}><Link className={`${st.links}`}>Dossier de presse</Link></div>
                        <div className={`row mb-4`}><Link className={`${st.links}`}>Partenaires</Link></div>
                    </div>
                    <div className="col-lg-2">
                        <div className={`row mb-3`}><b>Support</b></div>
                        <div className={`row mb-2`}><Link className={`${st.links}`}>Entrer en contact</Link></div>
                        <div className={`row mb-2`}><Link className={`${st.links}`}>Centre d'aide</Link></div>
                        <div className={`row mb-2`}><Link className={`${st.links}`}>Chat en direct</Link></div>
                        <div className={`row mb-4`}><Link className={`${st.links}`}>Comment ça fonctionne</Link></div>
                    </div>
                    <div className="col-lg-3">
                        <div className={`row mb-3`}><b>Contactez-nous</b></div>
                        <div className={`row ${st.links}`}><p>Email: {website && website.email ? website.email : "support@menuonline.tn"}</p></div>
                        <div className={`row ${st.links} mb-2`}><p>Phone:  (+216) 50 344 632</p></div>
                        <div className={`row text-center`}>
                            <div className="col-3">
                                <Link to={`${website && website.socials.facebook ? website.socials.facebook : "http://facebook.com"}`} className={`${st.fb}`}><i className={`${st.socials} bi bi-facebook`}></i></Link>
                            </div>
                            <div className="col-3">
                                <Link to={`${website && website.socials.x ? website.socials.x : "http://x.com"}`} className={`${st.tw}`}><i className={`${st.socials} bi bi-twitter-x`}></i></Link>
                            </div>
                            <div className="col-3">
                                <Link to={`${website && website.socials.youtube ? website.socials.youtube : "http://youtube.com"}`} className={`${st.ytb}`}><i className={`${st.socials} bi bi-youtube`}></i></Link>
                            </div>
                            <div className="col-3">
                                <Link to={`${website && website.socials.instagram ? website.socials.instagram : "http://instagram.com"}`} className={`${st.inst}`}><i className={`${st.socials} bi bi-instagram`}></i></Link>
                            </div>
                        </div>
                    </div>
                </div>
                <hr className={st.hr} />
                <div className="row">
                    <div className="col text-center text-muted mt-2 mb-3">
                        2023 © MenuOnline. All rights reserved. Powered by i<span style={{ color : "#FFCC44" }}>V</span>sane
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
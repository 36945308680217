import React, { useState, useEffect } from 'react';
import st from "./partnershipRequests.module.css";
import { getRequests } from '../../../../services/adminApis';

import AddBusinessIcon from '@mui/icons-material/AddBusiness';

import Pagination from '../../../pagination/pagination';
import TreatProviderRequest from '../../../modals/treatProviderRequest/treatProviderRequest';

function PartnershipRequests() {

    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 10;

    const [search, setSearch] = useState("");
    const [filter, setFilter] = useState('all');
    const [sort, setSort] = useState('recent');

    const [requests, setRequests] = useState([]);
    const [requestsToPaginate, setRequestsToPaginate] = useState([]);
    const [selectedRequest, setSelectedRequest] = useState({});

    useEffect(() => {
        getRequests((cb) => {
            if (cb.status === 200) {
                if (search === "") {
                    let filtered;
                    if (filter === "all") {
                        setRequestsToPaginate(cb.data.requests);
                        filtered = cb.data.requests;
                    } else if (filter === "treated") {
                        setRequestsToPaginate(cb.data.treated);
                        filtered = cb.data.treated;
                    } else if (filter === "notTreated") {
                        setRequestsToPaginate(cb.data.notTreated);
                        filtered = cb.data.notTreated;
                    } else if (filter === "held") {
                        setRequestsToPaginate(cb.data.held);
                        filtered = cb.data.held;
                    } else if (filter === "denied") {
                        setRequestsToPaginate(cb.data.denied);
                        filtered = cb.data.denied;
                    }

                    sort === "recent" && setRequestsToPaginate(filtered)
                    sort === "old" && setRequestsToPaginate(filtered.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)));
                    sort === "nameAZ" && setRequestsToPaginate(filtered.sort((a, b) => a.lastname.localeCompare(b.lastname)))
                    sort === "nameZA" && setRequestsToPaginate(filtered.sort((a, b) => b.lastname.localeCompare(a.lastname)))
                }
                else {
                    setRequestsToPaginate(cb.data.requests.filter((request) => {
                        for (let key in request) {
                            if (typeof request[key] === 'string' && request[key].toLowerCase().includes(search.toLowerCase())) {
                                return true;
                            }
                        }
                        return false;
                    }));
                }
            }
        })
        setItemOffset(0)
        setPageCount(0)
    }, [filter, search, sort])

    return (
        <><TreatProviderRequest request={selectedRequest} />
            <div className="contianer-fluid p-4">
                
                
                <div className="row">
                    <div className="col-lg-5 text-center">
                        <h3>Demandes de partenariat</h3>
                    </div>
                    <div className="col-lg-7 mb-2">
                        <div className={`${st.inputGroup}`}>
                            <input onChange={(event) => { setSearch(event.target.value) }} value={search}
                                type="text" placeholder="Rechercher une demande" className={`border-0 bg-light ${st.formControl}`} />
                            <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
                        </div>
                    </div>
                </div>
                <div className="row" >
                    <div className="col-lg-8"></div>
                    <div className="col-lg-4 mt-3">
                        <div className="row">
                            <div className="col-6"></div>
                            <div className="col-6">
                                <div className="row">
                                    <div className="col-6 px-0 text-end">
                                        <div className="dropdown-center">
                                            <span className={`btn btn-dark dropdown-toggle ${st.filter}`} data-bs-toggle="dropdown" aria-expanded="false">Filter</span>
                                            <ul className="dropdown-menu">
                                                <li><span className={`dropdown-item ${st.filterings} ${filter === 'all' ? "active" : ""}`} onClick={() => { setFilter('all') }}>Tous</span></li>
                                                <li><span className={`dropdown-item ${st.filterings} ${filter === 'treated' ? "active" : ""}`} onClick={() => { setFilter('treated') }}>Traitées</span></li>
                                                <li><span className={`dropdown-item ${st.filterings} ${filter === 'held' ? "active" : ""}`} onClick={() => { setFilter('held') }}>En cours</span></li>
                                                <li><span className={`dropdown-item ${st.filterings} ${filter === 'notTreated' ? "active" : ""}`} onClick={() => { setFilter('notTreated') }}>Non traitées</span></li>
                                                <li><span className={`dropdown-item ${st.filterings} ${filter === 'denied' ? "active" : ""}`} onClick={() => { setFilter('denied') }}>Réfusées</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="col-6 text-end">
                                        <div className="dropdown-center">
                                            <span className={`btn btn-dark dropdown-toggle ${st.sort}`} data-bs-toggle="dropdown" aria-expanded="false">Sort</span>
                                            <ul className="dropdown-menu">

                                                <li><span className={`dropdown-item ${st.sortings} ${sort === 'recent' ? "active" : ""}`} onClick={() => { setSort('recent') }}>Récentes</span></li>
                                                <li><span className={`dropdown-item ${st.sortings} ${sort === 'old' ? "active" : ""}`} onClick={() => { setSort('old') }}>Ancien </span></li>
                                                <li><span className={`dropdown-item ${st.sortings} ${sort === 'nameAZ' ? "active" : ""}`} onClick={() => { setSort('nameAZ') }}>Nom A à Z</span></li>
                                                <li><span className={`dropdown-item ${st.sortings} ${sort === 'nameZA' ? "active" : ""}`} onClick={() => { setSort('nameZA') }}>Nom Z à A</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className={`row ${st.tableBox}`}>
                    <div className={`col`} style={{ overflow: "auto", marginBottom: "10px" }}>
                        {requests && requests.length > 0 ?
                            <table className={`table`}>
                                <thead>
                                    <tr>
                                        <th style={{ minWidth: "100px" }}>Nom </th>
                                        <th style={{ minWidth: "100px" }}>Prénom </th>
                                        <th style={{ minWidth: "0px" }}>Téléphone </th>
                                        <th style={{ minWidth: "120px" }}>Adresse </th>
                                        <th style={{ minWidth: "200px" }}>Établissement </th>
                                        <th style={{ minWidth: "98px" }}>Soumise le</th>
                                        <th style={{ minWidth: "96px", textAlign: "center" }}>Status </th>
                                        <th style={{ minWidth: "3px" }}>Actions </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {requests.map((req, key) =>
                                        <tr key={key}>
                                            <td style={{ paddingTop: "15px" }}>{req.lastname}</td>
                                            <td style={{ paddingTop: "15px" }}>{req.firstname}</td>
                                            <td style={{ paddingTop: "15px" }}>{req.phone}</td>
                                            <td style={{ paddingTop: "15px" }}>{req.address}</td>
                                            <td style={{ paddingTop: "15px" }}>{req.businessname}</td>
                                            <td style={{ paddingTop: "15px" }}>{req.createdAt.slice(0, 10)}</td>
                                            <td style={{ paddingTop: "15px", textAlign: "center" }}>
                                                {req.status === "notTreated" && <div className={"text-warning"}>Non traitée</div>}
                                                {req.status === "treated" && <div className={"text-success"}>Traitée</div>}
                                                {req.status === "held" && <div className={"text-primary"}>En cours</div>}
                                                {req.status === "denied" && <div className={"text-danger"}>Refusée</div>}
                                            </td>
                                            <td style={{}}>
                                                <button onClick={()=>{setSelectedRequest(req)}} data-bs-toggle="modal" data-bs-target="#treatProviderRequest" style={{ marginRight: "5px" }} className={`btn ${st.AddBusinessIcon}`}><AddBusinessIcon /></button>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                            : ""}

                    </div>
                    <Pagination pageCount={pageCount} setPageCount={setPageCount} itemsPerPage={itemsPerPage} itemOffset={itemOffset} setItemOffset={setItemOffset} items={requestsToPaginate} setCurrentItems={setRequests} />
                </div>
            </div>
        </>

    );
}

export default PartnershipRequests;
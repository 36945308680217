import React, { useState } from 'react';

import st from "./admin-sidebar.module.css"
import { Sidebar, Menu, MenuItem, SubMenu, menuClasses } from 'react-pro-sidebar';

import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

function AdminSidebar(props) {

    const { collapsed, toggled, setToggled, setComponent } = props;
    const [hasImage] = useState(false);
    const [theme] = useState('light');

    const themes = {
        light: {
            sidebar: {
                backgroundColor: '#ffffff',
                color: '#607489',
            },
            menu: {
                menuContent: '#fbfcfd',
                icon: '#ffcc44',
                hover: {
                    backgroundColor: '#000000',
                    color: '#ffffff',
                },
                disabled: {
                    color: '#9fb6cf',
                },
            },
        },
        dark: {
            sidebar: {
                backgroundColor: '#0b2948',
                color: '#8ba1b7',
            },
            menu: {
                menuContent: '#082440',
                icon: '#59d0ff',
                hover: {
                    backgroundColor: '#00458b',
                    color: '#b6c8d9',
                },
                disabled: {
                    color: '#3e5e7e',
                },
            },
        },
    };
    const hexToRgba = (hex, alpha) => {
        const r = parseInt(hex.slice(1, 3), 16);
        const g = parseInt(hex.slice(3, 5), 16);
        const b = parseInt(hex.slice(5, 7), 16);

        return `rgba(${r}, ${g}, ${b}, ${alpha})`;
    };
    const menuItemStyles = {
        root: {
            fontSize: '13px',
            fontWeight: 400,
        },
        icon: {
            color: themes[theme].menu.icon,
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
        },
        SubMenuExpandIcon: {
            color: '#b6b7b9',
        },
        subMenuContent: ({ level }) => ({
            backgroundColor:
                level === 0
                    ? hexToRgba(themes[theme].menu.menuContent, hasImage && !collapsed ? 0.4 : 1)
                    : 'transparent',
        }),
        button: {
            [`&.${menuClasses.disabled}`]: {
                color: themes[theme].menu.disabled.color,
            },
            '&:hover': {
                backgroundColor: hexToRgba(themes[theme].menu.hover.backgroundColor, hasImage ? 0.8 : 1),
                color: themes[theme].menu.hover.color,
            },
        },
        label: ({ open }) => ({
            fontWeight: open ? 600 : undefined,
        }),
    };
    return (

        <div className={`${st.mainContainer}`}>
            <Sidebar
                width="250px"
                className={`${st.sidebar}`}
                collapsed={collapsed}
                toggled={toggled}
                onBackdropClick={() => setToggled(false)}
                breakPoint="md"
                backgroundColor={hexToRgba(themes[theme].sidebar.backgroundColor, hasImage ? 0.9 : 1)}

            >
                <div className={`${st.head} row`} >
                    <div className={`col-4 ps-4 ${st.logoContainer}`}><img className={`${st.logo}`} src='./images/logo-icon.png' alt='logo' /></div>
                    <div className={`${st.title} col-8 px-0`}><strong>MenuOnline</strong></div>
                </div>

                <div className={`${st.overflow}`}>
                    <Menu menuItemStyles={menuItemStyles}>
                        <SubMenu icon={<TravelExploreIcon />} label="Site web">
                            <MenuItem onClick={() => setComponent("home")}>Accueil</MenuItem>
                            <MenuItem onClick={() => setComponent("about")}>À propos</MenuItem>
                            <MenuItem onClick={() => setComponent("blog")}>Blog</MenuItem>
                            <MenuItem onClick={() => setComponent("contact")}>Contact</MenuItem>
                        </SubMenu>
                        <MenuItem icon={<GridViewRoundedIcon />} onClick={() => setComponent("dashboard")}>Tableau de bord</MenuItem>
                        <SubMenu icon={<RestaurantOutlinedIcon />} label="Prestataires">
                            <MenuItem onClick={() => setComponent("requestsList")}> Demandes de partenariat</MenuItem>
                            <MenuItem onClick={() => setComponent("providersList")}> Liste des prestataires</MenuItem>
                        </SubMenu>
                        <SubMenu icon={<ManageAccountsOutlinedIcon />} label="Utilisateurs" >
                            <MenuItem onClick={() => setComponent("usersList")}> Liste des utilisateurs</MenuItem>
                            <MenuItem onClick={() => setComponent("archivedUsersList")}> Liste des utilisateurs archivés</MenuItem>
                        </SubMenu>
                        <SubMenu icon={<SettingsOutlinedIcon />} label="Configurations" >
                            <MenuItem onClick={() => setComponent("emailConfig")} > E-mail</MenuItem>
                            <MenuItem> Informations du site</MenuItem>
                        </SubMenu>
                    </Menu>
                </div>
            </Sidebar>
        </div>


    );
}

export default AdminSidebar;
import ClientNavbar from "../../components/navigation/client-navbar/client-navbar";
import st from "./security.module.css";
import ChangeEmail from "../../components/user-security/change-email/change-email";
import ChangePassword from "../../components/user-security/change-password/change-password";

function Security() {
  return (
    <div className="app-page">
      <ClientNavbar />
      <div className={`container ${st.body}`}>
        <div className="row">
          <div className="col-xl-6">
            <ChangePassword/>
          </div>
          <div className="col-xl-6">
            <div className="row">
              <div className="col-12">
                <ChangeEmail/>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="card mb-4">
                  <div className={`${st.cardheader} card-header`}>
                    Désactiver votre compte{" "}
                  </div>
                  <div className="card-body">
                    <p>
                      La désactivation de votre compte est temporaire. Votre
                      compte et votre profil seront désactivés et votre nom et
                      vos photos seront supprimés.
                    </p>
                    <div className="text-end mt-2">
                      <button className={`${st.delBtn}`} type="submit">
                            J'ai compris, désactiver mon compte
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Security;

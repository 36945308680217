import { Link, useLocation } from "react-router-dom";
import st from "./client-navbar.module.css";
function ClientNavbar() {
  const role = localStorage.getItem("role") || sessionStorage.getItem("role");
  const location = useLocation();

  return (
    <div className={st.navbar}>
      <div className="container">
        <nav>
          <ul className={`${st.navList}`}>
          {role === 'provider' &&
            <li className={`${st.navListLinks}`}>
              <Link to="/account/dashboard" className={`${st.navLinks} ${location.pathname === '/account/dashboard' ? st.active : ''}`}>Dashboard</Link>
            </li>}
            <li className={`${st.navListLinks}`}>
              <Link to="/account/profile" className={`${st.navLinks} ${location.pathname === '/account/profile' ? st.active : ''}`}>Profil</Link>
            </li>
            <li className={`${st.navListLinks}`}>
              <Link to="/account/security" className={`${st.navLinks} ${location.pathname === '/account/security' ? st.active : ''}`}>Sécurité</Link>
            </li>
            {role === 'provider' &&
            <li className={`${st.navListLinks}`}>
              <Link to="/account/myPlaces" className={`${st.navLinks} ${location.pathname === '/account/myPlaces' ? st.active : ''}`}>Mes Places</Link>
            </li>}
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default ClientNavbar;

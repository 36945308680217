import React, { useEffect, useState } from 'react';
import st from "./userList.module.css"
import { getUsers } from '../../../../services/adminApis';

import LockResetIcon from '@mui/icons-material/LockReset';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import Pagination from '../../../pagination/pagination';

function UsersList(props) {
    const [search, setSearch] = useState("");

    const [usersToPaginate, setUsersToPaginate] = useState();
    const [itemOffset, setItemOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const itemsPerPage = 10;

    const [users, setUsers] = useState();
    const [filter, setFilter] = useState('all');
    const [sort, setSort] = useState('nothing');

    useEffect(() => {
        getUsers((cb) => {
            if (cb.status === 200) {
                if (search === "") {
                    let filtered
                    if (filter === 'all') {
                        setUsersToPaginate(cb.data.activeUsers.concat(cb.data.timeoutedUsers));
                        filtered = cb.data.activeUsers.concat(cb.data.timeoutedUsers);
                    } else if (filter === 'active') {
                        setUsersToPaginate(cb.data.activeUsers);
                        filtered = cb.data.activeUsers;
                    } else if (filter === 'timeout') {
                        setUsersToPaginate(cb.data.timeoutedUsers);
                        filtered = cb.data.timeoutedUsers
                    }

                    sort === "nameAZ" && setUsersToPaginate(filtered)
                    sort === "nameAZ" && setUsersToPaginate(filtered.sort((a, b) => a.lastname.localeCompare(b.lastname)))
                    sort === "nameZA" && setUsersToPaginate(filtered.sort((a, b) => b.lastname.localeCompare(a.lastname)))

                } else {
                    setUsersToPaginate((cb.data.activeUsers.concat(cb.data.timeoutedUsers)).filter((user) => {
                        for (let key in user) {
                            if (typeof user[key] === 'string' && user[key].toLowerCase().includes(search.toLowerCase())) {
                                return true;
                            }
                        }
                        return false;
                    }));
                }
            }
        })
        setItemOffset(0)
        setPageCount(0)
    }, [search, filter, sort])


    return (
        <div className="contianer-fluid p-4">
            <div className="row">
                <div className="col-lg-5 text-center">
                    <h3>Liste des utilisateurs</h3>
                </div>
                <div className="col-lg-7 mb-2">
                    <div className={`${st.inputGroup}`}>
                        <input onChange={(event) => { setSearch(event.target.value) }} value={search}
                            type="text" placeholder="Rechercher un utilisateur" className={`border-0 bg-light ${st.formControl}`} />
                        <div className={`${st.inputIcon}`}><i id='icon1' className='bi bi-search'></i></div>
                    </div>
                </div>
            </div>
            <div className="row" >
                <div className="col-lg-8"></div>
                <div className="col-lg-4 mt-3">
                    <div className="row">
                        <div className="col-6"></div>
                        <div className="col-6">
                            <div className="row">
                                <div className="col-6 px-0 text-end">
                                    <div className="dropdown-center">
                                        <span className={`btn btn-dark dropdown-toggle ${st.filter}`} data-bs-toggle="dropdown" aria-expanded="false">Filter</span>
                                        <ul className="dropdown-menu">
                                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'all' ? "active" : ""}`} onClick={() => { setFilter('all') }}>Tous</span></li>
                                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'active' ? "active" : ""}`} onClick={() => { setFilter('active') }}>Actif</span></li>
                                            <li><span className={`dropdown-item ${st.filterings} ${filter === 'timeout' ? "active" : ""}`} onClick={() => { setFilter('timeout') }}>Suspendu</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-6 text-end">
                                    <div className="dropdown-center">
                                        <span className={`btn btn-dark dropdown-toggle ${st.sort}`} data-bs-toggle="dropdown" aria-expanded="false">Sort</span>
                                        <ul className="dropdown-menu">
                                            <li><span className={`dropdown-item ${st.sortings} ${sort === 'nothing' ? "active" : ""}`} onClick={() => { setSort('nothing') }}>Rien</span></li>
                                            <li><span className={`dropdown-item ${st.sortings} ${sort === 'nameAZ' ? "active" : ""}`} onClick={() => { setSort('nameAZ') }}>Nom A à Z</span></li>
                                            <li><span className={`dropdown-item ${st.sortings} ${sort === 'nameZA' ? "active" : ""}`} onClick={() => { setSort('nameZA') }}>Nom Z à A</span></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div className={`row ${st.tableBox}`}>
                <div className={`col`} style={{ overflow: "auto", marginBottom: "10px" }}>
                    {users && users.length > 0 ?
                        <table className={`table`}>
                            <thead>
                                <tr>
                                    <th>Avatar</th>
                                    <th style={{ minWidth: "120px" }}>Nom </th>
                                    <th style={{ minWidth: "120px" }}>Prénom </th>
                                    <th style={{ minWidth: "200px" }}>Adresse e-mail </th>
                                    <th style={{ minWidth: "165px" }}>Statut </th>
                                    <th style={{ minWidth: "121px" }}>Actions </th>
                                </tr>
                            </thead>
                            <tbody>
                                {users.map((user, key) =>
                                    <tr key={key}>
                                        <td>
                                            <img src={user.avatar ? user.avatar.includes("http") ?
                                                user.avatar :
                                                process.env.REACT_APP_SERVER_PUBLIC + "" + user.avatar : "./images/userdefault.png"}
                                                alt="avatar" className={`${st.avatar}`} />
                                        </td>
                                        <td style={{ paddingTop: "20px" }}>{user.lastname}</td>
                                        <td style={{ paddingTop: "20px" }}>{user.firstname}</td>
                                        <td style={{ paddingTop: "20px" }}>{user.email}</td>
                                        <td style={{ paddingTop: "20px" }}>{
                                            user.timeout && (new Date(user.timeout) > new Date()) ? (
                                                <div className='text-danger'> {`Suspendu ${Math.ceil((new Date(user.timeout) - new Date()) / (1000 * 60 * 60 * 24))} jour(s)`}</div>
                                            ) : (!user.tiemout && !user.isDeleted && !user.isSuspended && <div className='text-success'>Actif</div>)
                                        }</td>
                                        <td style={{ paddingTop: "13px" }}>
                                            <button style={{ marginRight: "5px" }} className={`btn ${st.LockResetIcon}`}><LockResetIcon /></button>
                                            {user.timeout && (new Date(user.timeout) > new Date())
                                                ? (
                                                    <button className={`btn btn-dark ${st.ResetTime}`}>
                                                        <TimerOutlinedIcon />

                                                    </button>
                                                ) : (
                                                    <button className={`btn btn-dark ${st.PersonRemove}`}>
                                                        <PersonRemoveOutlinedIcon />
                                                    </button>
                                                )}

                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                        : ""}

                </div>
                <Pagination pageCount={pageCount} setPageCount={setPageCount} itemsPerPage={itemsPerPage} itemOffset={itemOffset} setItemOffset={setItemOffset} items={usersToPaginate} setCurrentItems={setUsers} />
            </div>
        </div>
    );
}

export default UsersList;
function MyPlaces() {
  return (
    <div>
      <div style={{ marginTop: "200px" }}>
        <h1>places</h1>
      </div>
    </div>
  );
}

export default MyPlaces;

import React, { useEffect } from 'react';
import ReactPaginate from 'react-paginate';
import st from './pagination.module.css'

function Pagination(props) {
    const { items, setCurrentItems, itemOffset, setItemOffset, pageCount, setPageCount, itemsPerPage } = props

    useEffect(() => {
        const endOffset = itemOffset + itemsPerPage;
        if (items) {
            setCurrentItems(items.slice(itemOffset, endOffset));
            setPageCount(Math.ceil(items.length / itemsPerPage));
        }
    }, [itemOffset, itemsPerPage, items, setCurrentItems, setPageCount])

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
    };

    return (<>
        <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="<"
            renderOnZeroPageCount={null}
            
            
            containerClassName={st.container}
            className={st.pagination}

            pageClassName={st.page}
            pageLinkClassName={st.pageLink}

            breakClassName={st.break}
            breakLinkClassName={st.breakLink}

            previousClassName={st.previous}
            previousLinkClassName={st.previousLink}

            nextClassName={st.next}
            nextLinkClassName={st.nextLink}

            activeClassName={st.active}
            activeLinkClassName={st.activeLink}

            disabledClassName={st.disabled}
            disabledLinkClassName={st.disabledLink}

        /></>);
}

export default Pagination;
import React from 'react';
import Footer from '../../components/footer/footer';

function AboutUs(props) {

    return ( <div className='app-page'>
        <h1 className='d-flex justify-content-center' style={{ backgroundColor : "Blue"}}>TEST</h1>
        <Footer website={props.website} />
    </div> );
}

export default AboutUs;
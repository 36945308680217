import { useState } from "react";
import { changeAccountPassword} from "../../../services/userApi";
import st from "./change-password.module.css"

function ChangePassword() {

    const initialValues = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      };

      const [passFormValues, setPassFormValues] = useState(initialValues);
      const [err, setErr] = useState(false);

      const handleChange = (event) => { 
        setErr(false)
        const { name, value } = event.target;
        setPassFormValues({ ...passFormValues, [name]: value });
      };

      const changePass = (event) => {
        event.preventDefault();
        let passwordPattern = /^(?=.*\d)(?=.*[A-Z]).{8,}$/;
    
        if (
          !passFormValues.oldPassword ||
          !passFormValues.newPassword ||
          !passFormValues.confirmNewPassword
        ) {
          setErr("req");
          return;
        }
        if (!passwordPattern.test(passFormValues.newPassword)) {
          setErr("pattern");
          return;
        }
        if (passFormValues.newPassword !==  passFormValues.confirmNewPassword) {
          setErr("notMatch");
          return;
        }
        const updates = {
          oldpassword: passFormValues.oldPassword,
          newpassword: passFormValues.newPassword,
        };
    
        changeAccountPassword(updates, (cb) => {
          if (cb.status === 200) {
            setTimeout(() => {
              setErr("success");
            }, 2000);
            return;
          } else if (cb.response.status === 401) {
            setErr("wrongPassword");
            return;
          } else if (cb.response.status === 400) {
            setErr("googleAccount");
            return;
          } else if (cb.response.status === 409) {
            setErr("same");
            return;
          } else if (cb.response.status === 406) {
            setErr("similar");
            return;
          } else {
            setErr("server");
            return;
          }
        });
      };

    return ( 
        <div className="card mb-4">
              <div className={`${st.cardheader} card-header`}>
                Changer votre mot de passe
              </div>
              <div className="card-body">
                <form onSubmit={changePass}>
                  <div className="mb-3">
                    <label className="small mb-1">Mot de passe actuel<span className="text-danger">*</span></label>
                    <input
                      className={`form-control ${(err === "req" && !passFormValues.oldPassword) || err === "wrongPassword" || err === "googleAccount" || err === "same" || err === "similar" || err === "server" ? "is-invalid" : ""}`}
                      type="password"
                      placeholder="Entrez votre mot de passe actuel"
                      onChange={handleChange}
                      name="oldPassword"
                      value={passFormValues.oldPassword}
                    />
                  </div>

                  <div className="mb-3">
                    <label className="small mb-1">Nouveau mot de passe<span className="text-danger">*</span></label>
                    <input
                      className={`form-control ${(err === "req" && !passFormValues.newPassword) || err === "pattern" || err === "notMatch" || err === "googleAccount" || err === "same" || err === "similar" || err === "server" ? "is-invalid" : ""}`}
                      type="password"
                      placeholder="Entrez un nouveau mot de passe"
                      onChange={handleChange}
                      name="newPassword"
                      value={passFormValues.newPassword}
                    />
                  </div>

                  <div className="mb-2">
                    <label className="small mb-1">Confirmez le mot de passe<span className="text-danger">*</span></label>
                    <input
                      className={`form-control ${(err === "req" && !passFormValues.confirmNewPassword) || err === "notMatch" ? "is-invalid" || err === "googleAccount" || err === "server" : ""}`}
                      type="password"
                      placeholder="Confirmer le nouveau mot de passe"
                      onChange={handleChange}
                      name="confirmNewPassword"
                      value={passFormValues.confirmNewPassword}
                    />
                  </div>
                  <div className="row">
                    <div className="col text-success">
                    {err === "success" && "le mot de passe a été modifié avec succès"}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col text-danger">
                      {err === "req" && "Tous les champs sont obligatoires !"}
                      {err === "pattern" && "Le mot de passe doit contenir au moins 8 caractères et inclure une lettre majuscule ainsi qu'un chiffre."}
                      {err === "notMatch" && "Les mots de passe ne correspondent pas !"}
                      {err === "wrongPassword" && "Mot de passe incorrect !"}
                      {err === "googleAccount" && "C'est un compte Google !"}
                      {err === "same" && "Le mot de passe est le même que l'ancien !"}
                      {err === "similar" && "le mot de passe ressemble à l'ancien !"}
                      {err === "server" && "Un problème survenue, réessayer plus tard !"}
                    </div>
                  </div>
                  <div className="text-center mt-2">
                    <button className={`${st.myBtn}`} type="submit">
                      Enregister
                    </button>
                  </div>
                </form>
              </div>
            </div>
     );
}

export default ChangePassword;
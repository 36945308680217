import axios from 'axios'
axios.defaults.withCredentials = true;

//Get all users
export const getUsers = (callback)=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/users`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Get all requests
export const getRequests = (callback)=>{
    axios.get(`${process.env.REACT_APP_BASE_URL}/admin/requests`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}

//Suspend a user
export const suspendUser = (id, callback)=>{
    axios.post(`${process.env.REACT_APP_BASE_URL}/admin/user/${id}`)
        .then((res) => callback(res))
        .catch((err) => callback(err));
}
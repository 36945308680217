import st from "./treatProviderRequest.module.css"


function TreatProviderRequest(props) {

    const { request } = props;

    return (
        
        <div className={`modal fade`} id="treatProviderRequest" tabIndex="-1" aria-hidden="true">
            <div className={`modal-dialog`}>
                <div className={`modal-content `}>
                    <div className="row p-3">
                        <div className="col-10 pt-2 ps-4">
                            <h4 >Traitement de la demande</h4>
                        </div>
                        <div className="col-2 text-end">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    </div>
                    <div className="modal-body">
                        {request &&
                            <div className="row">
                                <div className="col">
                                    <p><strong>Nom :</strong>{request.lastname}</p>
                                    <p><strong>Prénom :</strong>{request.firstname}</p>
                                    <p><strong>Téléphone :</strong>{request.phone}</p>
                                    <p><strong>Adresse e-mail :</strong>{request.email}</p>
                                    <p><strong>Nom de l'établissement :</strong>{request.businessname}</p>
                                    <p><strong>Adresse :</strong>{request.address}</p>
                                </div>
                            </div>}
                        <div className="row">
                            <div className="col text-end">
                                <button className={`${st.close}`} data-bs-dismiss="modal">Fermer</button>
                                <button className={`${st.save}`}>Enregistrer</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TreatProviderRequest;


